import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";
import Personas from "../../icons/personas.svg";
import InformationArchitecture from "../../icons/sitemap.svg";
import PrototypeTesting from "../../icons/ui-ux-setup.svg";
import CustomerJourney from "../../icons/user-flow-user-journey.svg";
import MobileMockupsBackground from "../../images/portfolio/cg-custom-guide/bg-hero.png";

const PortfolioHeader = () => (
  <section className="bg-gray-700 py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">CG Custom Guide</p>
              <small className="text-muted">
                CG Custom Guide is a fake company name to protect our client’s
                identity.
              </small>
            </div>
            <div className="col-12 mt-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p>UX Design</p>
              <p>UI Design</p>
              <p>Graphic Design</p>
              <p>Interface Design</p>
              <p>Custom Software Development</p>
              <p>Zendesk Guide Customization</p>
              <p>API Integrations</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mt-5 mt-lg-0">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white">
            729 SOLUTIONS BROKE THROUGH TECHNICAL LIMITATIONS TO CREATE A FULLY
            CUSTOM ZENDESK GUIDE EXPERIENCE THAT STRETCHED THE PLATFORM’S
            BOUNDARIES
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const MobileMockups = () => (
  <section className="bg-secondary-light" style={{ height: "580px" }}>
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div
          className="col-12 p-0"
          style={{
            backgroundImage: `url(${MobileMockupsBackground})`,
            backgroundSize: "initial",
            backgroundRepeat: "repeat",
          }}
        />
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section className="bg-portfolio-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>
            CG Custom Guide came to 729 Solutions seeking help with making deep
            customizations to the Zendesk Guide experience that would walk
            customers and partners through the adoption lifecycle.
          </p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>Project Goals</h2>
          <ol className="text-primary">
            <li>
              <span className="text-dark">
                Go beyond providing support services and a knowledge base that’s
                normally used for Zendesk Guide.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Create an active community of partners, customers, and
                employees.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Aggregate content from disparate systems by way of a centralized
                database.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Gamify the process of stepping through the completion of
                enablement workflows.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Present information through each workflow stage in a uniform and
                cohesive manner by role.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Create single source for customers and partners to access all
                information.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Organize information based on persona and workflow.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Improve search experience in a way that empowers users to
                self-service.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Increase documentation distribution and updates cadence.
              </span>
            </li>
            <li>
              <span className="text-dark">
                Resolve issues with minimal friction.
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);

const ZendeskPartner = () => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6">
          <div className="p-4">
            <StaticImage
              src="../images/partners/zendesk-implementation-premier-partner-badge.png"
              alt="Zendesk Implementation Premier Partner"
              width={400}
              layout="constrained"
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <h2>729 is proud to be a Zendesk Implementation Premier Partner.</h2>
        </div>
      </div>
    </div>
  </section>
);

const HowWeDidIt = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 mb-5">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2 mt-5 mt-lg-0">Software Used</h2>
              <div className="row">
                <div className="col-4 col-lg-2 pr-0">
                  <StaticImage
                    src="../../images/tools/adobe-xd.png"
                    alt="Adobe Xd"
                    width={50}
                    height={50}
                  />
                </div>
                <div className="col-4 col-lg-2 pr-0">
                  <StaticImage
                    src="../../images/tools/adobe-illustrator.png"
                    alt="Adobe Illustrator"
                    width={50}
                    height={50}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <h2 className="mb-2">Development Languages Used</h2>
              <p className="mb-0">C#</p>
              <p className="mb-0">JavaScript</p>
              <p className="mb-0">HTML 5</p>
              <p className="mb-0">CSS 3</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">Frameworks Used</h2>
              <p className="mb-0">.net</p>
              <p className="mb-0">jQuery</p>
              <p className="mb-0">Bootstrap</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">Databases Used</h2>
              <p className="mb-0">MySQL</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">APIS</h2>
              <p className="mb-0">Zendesk</p>
              <p className="mb-0">Docebo</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Research Methods</h2>
              <div className="row align-items-center">
                <div className="col-2">
                  <CustomerJourney color="gray" />
                </div>
                <div className="col-10">
                  <p>Customer Journey</p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-2">
                  <PrototypeTesting color="gray" />
                </div>
                <div className="col-10">
                  <p>Prototype Testing</p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-2">
                  <InformationArchitecture color="gray" />
                </div>
                <div className="col-10">Information Architecture</div>
                <div className="col-2">
                  <Personas color="gray" />
                </div>
                <div className="col-10">
                  <p>Personas</p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="mb-2 mt-5">Tools Used</h2>
              <p className="mb-0">Zendesk Guide</p>
              <p className="mb-0">Browserstack</p>
              <p className="mb-0">AWS</p>
              <p className="mb-0">GitHub</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const WebMockups = () => (
  <section className="my-6 py-6 bg-secondary-light">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/cg-custom-guide/desktop-mockup-1.png"
            alt="CG Mockup 1"
          />
          <StaticImage
            className="mt-5"
            style={{ width: "100%" }}
            src="../../images/portfolio/cg-custom-guide/desktop-mockup-3.png"
            alt="CG Mockup 3"
          />
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/cg-custom-guide/desktop-mockup-2.png"
            alt="CG Mockup 2"
          />
          <StaticImage
            className="mt-5"
            style={{ width: "100%" }}
            src="../../images/portfolio/cg-custom-guide/desktop-mockup-4.png"
            alt="CG Mockup 4"
          />
        </div>
      </div>
    </div>
  </section>
);

const FocusAreasAndNeeds = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 pr-lg-4">
          <h2>Key Focus Areas</h2>
          <p>How can we help customers know what they don’t know?</p>
          <p>
            How can content/information from disparate sources be aggregated and
            categorized based on persona/role/workflow?
          </p>
          <p>
            What can be done to disseminate documentation and communicate
            critical updates?
          </p>
          <p>
            What methods can be used to structure engagement beyond support and
            services?
          </p>
          <p>
            How can self-service features be implemented in such a way that
            empowers users?
          </p>
          <p>
            How can we make workflows that are normally seen as tedious fun and
            even exciting?
          </p>
          <p>
            What can be put in place to allow customers and partners to support
            each other?
          </p>
        </div>
        <div className="col-12 col-lg-6 pl-lg-6 mt-5 mt-lg-0">
          <h2>Core Client Needs</h2>
          <div className="row">
            <div className="col-12 col-lg-6 mb-5">
              <p>
                <b>Client Need 1</b>
              </p>
              <p>Create a vibrant user community</p>
            </div>
            <div className="col-12 col-lg-6 mt-0">
              <p>
                <b>Client Need 2</b>
              </p>
              <p>
                Gamify/incentivize the progression and completion of workflows.
              </p>
            </div>
            <div className="col-12 col-lg-6 mt-5">
              <p>
                <b>Client Need 3</b>
              </p>
              <p>
                Provide single source where all information can be obtained.
              </p>
            </div>
            <div className="col-12 col-lg-6 mt-5">
              <p>
                <b>Client Need 4</b>
              </p>
              <p>Minimize/eliminate user experience friction.</p>
            </div>
            <div className="col-12 col-lg-6 mt-5">
              <p>
                <b>Client Need 5</b>
              </p>
              <p>Deliver workflow-based content logically and cohesively.</p>
            </div>
            <div className="col-12 col-lg-6 mt-5">
              <p>
                <b>Client Need 6</b>
              </p>
              <p>
                Scalability for implementing future workflows quickly/easily.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ActivityMockups = () => (
  <section className="my-6 pt-6 bg-primary-light">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <StaticImage
            style={{ width: "100%" }}
            src="../../images/portfolio/cg-custom-guide/activity-mockups.png"
            alt="Activity Mockups"
          />
        </div>
      </div>
    </div>
  </section>
);

const WhatWeLearned = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Learnings</h2>
        </div>
        <div className="col-12 col-lg-6">
          <p>
            CG Custom Guide came to 729 Solutions with very clear requirements
            and a strong vision. The challenge for 729 Solutions was to take the
            client’s ideas and implement them into the Zendesk platform which
            wasn’t intended to be used for what the client had in mind.
          </p>
          <p>
            From the beginning, it was critical that 729 Solutions work closely
            with the client to define user flows and diagrams. From there,
            prototypes could quickly be built using Adobe XD. This allowed the
            client and 729 Solutions to validate workflows before any code was
            written.
          </p>
        </div>
        <div className="col-12 col-lg-6">
          <p>
            729 Solutions was able to successfully aggregate data, build
            functionality based on complex workflows, and implement many deep
            customizations within the Zendesk platform that can be leveraged in
            future projects.
          </p>
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6 my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location}/>
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="CG Custom Guide"
      />
      <PortfolioHeader />
      <MobileMockups />
      <ProjectGoals />
      <ZendeskPartner />
      <HowWeDidIt />
      <WebMockups />
      <FocusAreasAndNeeds />
      <ActivityMockups />
      <WhatWeLearned />
      <ProjectForm location={location} />
      <PortfolioFeed />
    </Layout>
  );
};

export default Index;
